import "./App.css";
import Contactdata from "./components/Contactdata";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Servicecarousel from "./components/Servicecarousel";
import Welcome from "./components/Welcome";
import * as SunCalc from "suncalc";

function App() {
  const setDarkMode = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
  };

  const setLightMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
  };

  const getSunriseSunsetTime = (lat, long) => {
    const today = new Date();
    const sunriseSunset = SunCalc.getTimes(today, lat, long);
    return {
      sunrise: sunriseSunset.sunrise.getTime(),
      sunset: sunriseSunset.sunset.getTime(),
    };
  };

  const isDaytime = (lat, long) => {
    const times = getSunriseSunsetTime(lat, long);
    const now = Date.now();
    return now >= times.sunrise && now < times.sunset;
  };

  if (isDaytime(47.3768888, 8.541694)) {
    setLightMode();
  } else {
    setDarkMode();
  }

  return (
    <div className="App">
      <Home />
      <Welcome />
      <Servicecarousel />
      <Contactdata />
      <Footer />
    </div>
  );
}

export default App;
