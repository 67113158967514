import React from "react";
import "../assets/css/carouselpage.css";
import Carousel from "react-bootstrap/Carousel";
import Thermometer from "../assets/img/thermometer-snow.svg";
import Droplet from "../assets/img/droplet.svg";
import Lightbulb from "../assets/img/lightbulb.svg";
import Speaker from "../assets/img/speaker.svg";
import Camera from "../assets/img/camera-video.svg";
import Key from "../assets/img/key.svg";
import Home from "../assets/img/house.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import * as SunCalc from "suncalc";

function Servicecarousel() {
  AOS.init();
  let test = "dark";
  const getSunriseSunsetTime = (lat, long) => {
    const today = new Date();
    const sunriseSunset = SunCalc.getTimes(today, lat, long);
    return {
      sunrise: sunriseSunset.sunrise.getTime(),
      sunset: sunriseSunset.sunset.getTime(),
    };
  };

  const isDaytime = (lat, long) => {
    const times = getSunriseSunsetTime(lat, long);
    const now = Date.now();
    return now >= times.sunrise && now < times.sunset;
  };

  if (isDaytime(47.3768888, 8.541694)) {
    test = "dark";
  } else {
    test = "light";
  }

  return (
    <>
      <div className="page-container">
        <Carousel
          variant={test}
          data-aos="fade-up"
          className="carousel-container"
        >
          <Carousel.Item className="carousel-item">
            <img
              className="carousel-image"
              src={Thermometer}
              alt="First slide"
            />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">
                Heizung, Lüftung, Klimatisierung
              </h1>
              <p className="carousel-text">
                Home of Electric ist Ihr zuverlässiger Partner für die
                Modernisierung Ihrer Heizung, den Einbau einer neuen Badlüftung
                oder einer Klimaanlage für die heißen Sommertage. Wir bieten
                Ihnen eine umfassende Beratung und führen technische
                Installationen professionell und zuverlässig aus.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Droplet} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Sanitär</h1>
              <p className="carousel-text">
                Home of Electric bietet Ihnen Hilfe bei der Reparatur oder dem
                Austausch von defekten Haushaltsgeräten wie Boilern,
                Geschirrspülmaschinen und anderen Geräten. Wir helfen Ihnen bei
                der Suche nach effizienten Lösungen und unterstützen Sie bei der
                Umsetzung.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Lightbulb} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Elektro</h1>
              <p className="carousel-text">
                Home of Electric ist Ihr zuverlässiger Partner für
                Elektrotechnik im Wohnbereich. Ob es darum geht, eine Glühbirne
                auszutauschen oder Ihr Zuhause in ein Smart Home zu verwandeln,
                wir bieten Ihnen kundenfreundliche Gesamtlösungen, auf die Sie
                sich verlassen können.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Speaker} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Lautsprecher</h1>
              <p className="carousel-text">
                Homecinema im Wohnzimmer? Oder unter der Dusche Radio hören?
                Kein Problem! Wir bieten für Sie Gesamtlösungen für Ihr zu
                Hause.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Camera} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Überwachung, Alarmierung</h1>
              <p className="carousel-text">
                Wir bei Home of Electric möchten, dass Sie sich in Ihrem
                Eigenheim zu 100% sicher fühlen. Gerne beraten wir Sie und
                helfen Ihnen dabei, die richtigen Sicherheitsmaßnahmen zu
                ergreifen, um Ihr Zuhause zu schützen.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Key} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Zutrittskontrolle</h1>
              <p className="carousel-text">
                Wir bei Home of Electric wissen, dass niemand einen schweren
                Schlüsselbund herumtragen möchte. Deshalb bieten wir Ihnen eine
                bequeme Alternative: Unsere modernen Zugangssysteme ermöglichen
                es Ihnen, Ihre Türen einfach mit Ihrem Finger zu öffnen und zu
                schließen.
              </p>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item className="carousel-item">
            <img className="carousel-image" src={Home} alt="First slide" />
            <Carousel.Caption className="carousel-caption-position">
              <h1 className="carousel-title">Home Services</h1>
              <p className="carousel-text">
                Wir bei Home of Electric wissen, wie wichtig es ist, ein
                komfortables und funktionsfähiges Zuhause zu haben. Ob Sie mehr
                im Homeoffice arbeiten möchten oder eine TV-Wand montieren
                möchten - wir stehen Ihnen mit fachlicher Hilfe zur Seite, um
                sicherzustellen, dass alles perfekt funktioniert.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
}
export default Servicecarousel;
